import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Box,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PapFooter from 'components/pap-footer.js';
import PapProjectDetail from '../../components/pap-project-detail';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoDowinWhite from "images/logodowinWhite.png";
import { auth } from '../../../firebase-info';

const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	margin: '16px',
	marginTop: '0px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const heavyBlue = {
	backgroundColor: '#2C90CB',
}
const titleWhite = {
	fontFamily: 'Quicksand',
	color: '#FFFFFF',
	fontSize: '36px',
	lineHeight: '45px',
	fontWeight: "700",
}
const subtitleWhite = {
	fontFamily: 'Quicksand',
	color: '#FFFFFF',
	fontSize: '26px',
	lineHeight: '30px',
	fontWeight: "500",
}
const logout = {
	marginBottom: '20px',
	marginLeft: 'auto',
	verticalAlign: 'middle',
}
const headerLink = {
    textDecoration: 'none',
    color: '#FFF',
    fontWeight: 500,
}

function ProjectDetail(props) {
	const [loading, setLoading] =  React.useState(true);
	const { location } = props;

	async function checkPrivate() {
		auth.onAuthStateChanged(function(user) {
			if(!user){
				navigate("/solicitants");
				return;
			}
			setLoading(false);
		  });
	}

	React.useEffect(() => {
		checkPrivate();
	}, []);

	const theme = createTheme(
		{
			components: {
				MuiDivider: {
					styleOverrides: {
						root: {
							'&::before': {
								borderTop: 'thin solid rgba(255, 255, 255, 0.5)'
							},
							'&::after': {
								borderTop: 'thin solid rgba(255, 255, 255, 0.5)'
							}
						}
					}
				},
			},
		},
	);

	function _parseProjectType(type) {
		const typeEnum = {
			internal: "Sorteo interno sin notario.",
			external: "Sorteo ante notario.",
			lottery: "Sorteo o premio a través de la lotería nacional."
		}

		return typeEnum[type] || "Sin datos";
	}

	function performLogout() {
		auth.signOut();
		navigate("/");
	}

	return (
		<main style={{backgroundColor: '#000', height: '100%'}}>
			{loading ?
				<></>
			:
				<ThemeProvider theme={theme}>
					<Box style={pageStyles}>
						<Box style={heavyBlue}>
							<Grid container spacing={2} style={topBar}>
								<Grid item xs={11}>
									<Link to="/">
										<img
											alt="dowin Logo"
											style={imageContainer}
											src={LogoDowinWhite}
										/>
									</Link>
									<Box style={headerMargin}>
										<Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
									</Box>
									<Box style={headerMargin}>
										<Link  style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/dashboard"}> Mis proyectos </Link>
									</Box>
									<Box style={headerMargin}>
										<Link  style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/profile"}> Mi Perfil </Link>
									</Box>
								</Grid>
								<Grid item xs={1}>
									<IconButton style={logout} aria-label="Cerrar sesión" size="large">
										<LogoutIcon onClick={performLogout} fontSize="inherit" style={{fill: '#FFFFFF'}}/>
									</IconButton>
								</Grid>
							</Grid>
							<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
								<Grid item textAlign={"center"} xs={10}>
									<Typography variant="h1" style={titleWhite}>Detalle del proyecto</Typography>
								</Grid>
								<Grid item xs={10}>
									<Typography variant="h3" style={subtitleWhite}>Recuerda que una vez un proyecto ha comenzado o está finalizado, no podrás realizar ningún cambio.</Typography>
								</Grid>
								<PapProjectDetail size={10} title={"Título"} texts={[location.state.projectData.title]} />
								<PapProjectDetail size={10} isMD={true} title={"Descripción"} texts={[location.state.projectData.description]} />
								<PapProjectDetail
									size={6}
									title={"Participaciones: "}
									texts={[
										`Participaciones totales solicitadas: ${location.state.projectData.totalTickets}`,
										`Donaciones recibidas: ${location.state.projectData.ticketsSold}`,
										`Precio donación solicitado: ${location.state.projectData.price} €`,
										`Objetivo de recaudación: ${location.state.projectData.goal} €`,
									]}
								/>
								<PapProjectDetail
									size={3}
									title={"Fechas: "}
									texts={[
										`Fecha de comienzo: ${location.state.projectData.humanStartDate}`,
										`Fecha de Fin: ${location.state.projectData.humanEndDate}`,
										`Fecha del sorteo: ${location.state.projectData.humanDrawDate}`,
									]}
								/>
								<PapProjectDetail
									size={5}
									title={"Datos del proyecto: "}
									texts={[
										`Estado del proyecto: ${location.state.projectData.projectState}`,
										`Tipo de bonificación: ${_parseProjectType(location.state.projectData.type)}`,
									]}
								/>
							</Grid>
						</Box>
					</Box>
					<PapFooter/>
				</ThemeProvider>
			}
		</main>
	);
}

export default ProjectDetail