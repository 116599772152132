import * as React from 'react';
import { 
	Chip,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';


const chipStyle = {
    fontFamily: 'Quicksand',
    color: '#FFFFFF',
    fontSize: '26px',
    fontWeight: 500,
}
const subtitleWhite = {
	fontFamily: 'Quicksand',
	color: '#FFFFFF',
	fontSize: '26px',
	lineHeight: '30px',
	fontWeight: "500",
}

export default function PapProjectDetail(props) {
    return (
        <>
            <Grid item xs={10}>
                <Divider>
                    <Chip style={chipStyle} label={props.title} />
                </Divider>
            </Grid>
            {props.texts.map((text) =>
                <Grid style={{color: 'white'}} textAlign={props.isMD ? "left" : "center"} mt={'20px'} item xs={props.size}>
                    {props.isMD ?
                        <ReactMarkdown children={text} />
                        :
                        <Typography mt={"20px"} variant="h3" style={subtitleWhite}>{text}</Typography>
                    }
                </Grid>
            )}
        </>
    );
}